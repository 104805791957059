import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";
import {onAuthStateChanged, User} from "firebase/auth";
import {AuthSession} from "@/interfaces/auth.interface";
import {auth} from "@/config/firebase.config";

export const authGuard = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
): Promise<void> => {
    let currentUser = auth.currentUser;

    // If user is not signed in, redirect to login
    if (!currentUser) {
        currentUser = await fetchAuthState();
    }

    if (!currentUser) {
        console.error("Unauthorized access attempt");
        return next({path: "/login"}); // Redirect to unauthorized page
    }

    try {
        // Fetch the user's ID token and decode claims
        const idTokenResult = await currentUser?.getIdTokenResult(true);
        const claims: AuthSession = idTokenResult["claims"] as any;

        console.log(claims);

        // Check for custom claims or permissions
        if (!!claims.uid && !!claims.role) {
            next(); // Allow access to the route
        } else {
            console.error("Unauthorized access attempt");
            next({path: "/login"}); // Redirect to unauthorized page
        }
    } catch (error) {
        console.error("Error verifying auth claims:", error);
        next({path: "/login"}); // Redirect to login on error
    }
};


async function fetchAuthState(): Promise<User | null> {
// Return a Promise that resolves when the auth state is determined
    return new Promise((resolve) => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                resolve(user); // User is signed in
            } else {
                resolve(null); // User is not signed in
            }
        });
    });
}
