import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";


// PrimeVue related imports
import Tooltip from "primevue/tooltip";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import ToastService from "primevue/toastservice";
import DialogService from "primevue/dialogservice"; // Import the DialogService
import DynamicDialog from "primevue/dynamicdialog"; // Adjust the path as necessary
import ConfirmationService from "primevue/confirmationservice";

// Import Main styling scss file
import "@/assets/scss/styles.scss"; //
// Import Firebase configuration to initialize it
import "./config/firebase.config";

const app = createApp(App);

// Register PrimeVue and services
app.use(PrimeVue, {
    theme: {
        preset: Aura
    },
    unstyled: false
});

app.directive("tooltip", Tooltip);
app.component("DynamicDialog", DynamicDialog);

app.use(ToastService);
app.use(DialogService);  // Register the DialogService globally
app.use(ConfirmationService);  // Register the DialogService globally
app.use(router);




router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title as string;
    } else {
        document.title = "Member Portal | AAIM"; // Fallback title
    }
    next();
});

app.mount("#app");
