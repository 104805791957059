// src/services/spinner.service.ts
import {ref} from "vue";

const isLoading = ref(false);

const showSpinner = () => {
    isLoading.value = true;
};

const hideSpinner = () => {
    isLoading.value = false;
};

/**
 * Wraps a Firestore or any other promise to manage spinner visibility.
 * @param promise The promise to be executed.
 * @returns The resolved value of the promise.
 */
const withSpinner = async <T>(promise: Promise<T>): Promise<T> => {
    showSpinner();
    try {
        return await promise;
    } finally {
        hideSpinner();
    }
};

const useSpinner = () => ({
    isLoading,
    showSpinner,
    hideSpinner,
    withSpinner,
});

export default useSpinner;
