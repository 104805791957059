<template>
  <router-view></router-view>
  <Toast/>
  <DynamicDialog/>
  <ConfirmDialog group="templating">
    <template #message="slotProps">
      <div class="w-full p-6
                  flex flex-col items-center gap-4
                  border-b border-surface-200 dark:border-surface-700">
        <i :class="slotProps.message.icon" class="!text-6xl text-primary-500"></i>
        <p>{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmDialog>

  <!-- Spinner Overlay -->
  <div v-if="isLoading" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <ProgressSpinner/>
  </div>
</template>

<script setup lang="ts">
import DynamicDialog from 'primevue/dynamicdialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
import useSpinner from "@/services/spinner.service"; // Use PrimeVue spinner


const { isLoading } = useSpinner();


import { Scorm12API, Scorm2004API, AICC } from "scorm-again";

const scorm12Instance = new Scorm12API({ logLevel: 2 });
const scorm2004Instance = new Scorm2004API({ logLevel: 2 });
const aiccInstance = new AICC({ logLevel: 2 });

// Attach to global window object
if (typeof window !== "undefined") {
  window.API = scorm12Instance; // SCORM 1.2
  window.API_1484_11 = scorm2004Instance; // SCORM 2004
  window.AICC_API = aiccInstance; // AICC
}

console.log("SCORM APIs initialized:", { scorm12Instance, scorm2004Instance, aiccInstance });





console.log("SCORM 12 API:", scorm12Instance);
console.log("SCORM 2004 API:", scorm2004Instance);
console.log("AICC API:", aiccInstance);
console.log(window.API.initialize()); // For SCORM 1.2
console.log(window.API_1484_11.initialize()); // For SCORM 2004


</script>
